import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
/* eslint-disable import/no-extraneous-dependencies */
import '@firebase/polyfill';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import '@/scss/custom.scss';

Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(require('vue-moment'));

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
