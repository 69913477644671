module.exports = {
  eventId: 'vVbdLOVy3EBakXwBw1Gm',
  accountId: 'rqPbvpK7wEHmEQ8QuyqS',
  stages: [
    'zQK6mMJ4NYBLaeZ6K70o',
    'P3mIomtNXJYv5HJJ6LKE',
    'zgyIsKDU8nK1lto4Kv0I',
    'qsuBn4kAMRCJlhFZEXXo',
  ],
  activateCertificate: '24 05 2021 16:00',
  liveEventId: '90965a98-5df9-4a3b-ae2f-0dd59d47d7e8',
};
