<template>
  <!--Sponsor-->
  <section>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="box p-4">
            <h2 class="title text-center mb-4">Genel Sponsorlar</h2>
            <!--<h5 class="text-center mb-4">Atölye Genel Sponsorları</h5>-->
            <div class="row custom-gutter">
              <div class="col-md-6 mb-4">
                <div class="booth-logo">
                  <img src="/assets/abbvie/stand_logo.png" alt="" />
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="booth-logo">
                  <img src="/assets/amgen/stand_logo.png" alt="" />
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="booth-logo">
                  <img src="/assets/celltrion/stand_logo.png" alt="" />
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="booth-logo">
                  <img src="/assets/farmanova/stand_logo.png" alt="" />
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="booth-logo">
                  <img src="/assets/kocakfarma/stand_logo.png" alt="" />
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="booth-logo">
                  <img src="/assets/lilly/stand_logo.png" alt="" />
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="booth-logo">
                  <img src="/assets/ucb/stand_logo.png" alt="" />
                </div>
              </div>
            </div>

            <!--
            <h5 class="text-center mb-4">Atölye Genel Sponsorları</h5>
            <div class="row custom-gutter">
              <div class="col-md-6 mb-4">
                <img src="@/assets/images/Group 11.png" alt="" />
              </div>
              <div class="col-md-6 mb-4">
                <img src="@/assets/images/Group 13.png" alt="" />
              </div>
            </div>
            <h5 class="text-center mb-4">Teknoloji Sponsorları</h5>
            <div class="row custom-gutter mt-3">
              <div class="col-md-6" style="text-center">
                <img src="@/assets/images/Group 12.png" alt="" />
              </div>
              <div class="col-md-6" style="text-center">
                <img src="@/assets/images/Group 14.png" alt="" />
              </div>
            </div>
            -->
            <!--
            <div class="row" style="justify-content: center">
              <div class="col-md-6 mb-4" style="text-center">
                <router-link
                  class="booth-logo"
                  to="/booth/BP642iTGBimehU72fq91"
                >
                  <img src="/assets/sanovel/stand_logo.png" alt="" />
                  <button class="btn btn-secondary">Tıklayınız</button>
                </router-link>
              </div>
              <div class="col-md-6 mb-4" style="text-center">
                <router-link
                  class="booth-logo"
                  to="/booth/kX8Va23bynX31IFdOhz3"
                >
                  <img src="/assets/pfizer/stand_logo.png" alt="" />
                  <button class="btn btn-secondary">Tıklayınız</button>
                </router-link>
              </div>
              <div class="col-md-6 mb-4" style="text-center">
                <router-link
                  class="booth-logo"
                  to="/booth/O2BbZDj6LvHjkvvyzir7"
                >
                  <img src="/assets/nobel/stand_logo.png" alt="" />
                  <button class="btn btn-secondary">Tıklayınız</button>
                </router-link>
              </div>
            </div>
            -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.booth-logo {
  display: block;
  border-radius: 8px;
  box-shadow: 0 0 2px 1px rgba(154, 160, 185, 0.2);
  &:hover {
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
      0 15px 40px rgba(166, 173, 201, 0.2);
  }
  img {
    border-radius: 8px;
  }
}
</style>
